<template>
	<div>
		<div @click.prevent="connectWithOkra" v-if="!embed">
			<slot></slot>
		</div>
	</div>
</template>
<script>
import Okra from "npm-okrajs";
export default {
	props: {
		embed: {
			type: Boolean,
			required: false,
		},
		okraKey: {
			type: String,
			required: false,
		},
		token: {
			type: String,
			required: false,
		},
		env: {
			type: String,
			required: false,
			default: "production",
		},
		logo: {
			type: String,
			required: false,
		},
		name: {
			type: String,
			required: false,
			default: "Credpal",
		},
		products: {
			type: Array,
			required: false,
			/*default: () => {
				return [
					'Auth'
				] 
			}*/
		},
		color: {
			type: String,
			required: false,
		},
		limit: {
			type: Number,
			required: false,
		},
		widgetSuccess: {
			type: String,
			required: false,
		},
		widgetFailed: {
			type: String,
			required: false,
		},
		currency: {
			type: String,
			required: false,
			default: "NGN",
		},
		countries: {
			type: Array,
			required: false,
			default: () => {
				return ["NGN"];
			},
		},
		clientName: {
			type: String,
			required: false,
		},
		isCorporate: {
			type: Boolean,
			required: false,
		},
	},
	mounted() {
		if (this.embed) {
			this.connectWithOkra();
		}
	},
	methods: {
		connectWithOkra() {
			return Okra.buildWithShortUrl({
				short_url: "TI4jFlMhM",
				onSuccess: ({ auth }) => {
					this.$emit('success', auth)
				},
				onClose: () => console.log("closed"),
				onError: () => console.log("Widget error"),
			});
		},
	},
};
</script>